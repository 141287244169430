<template>
    <div>
        <b-card>               
            <div class="app-card-profile-title mb-3">{{trans('as-additional-information',217)}}</div>

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="false">
                <template v-slot:col-1>
                    <b-form-group>
                        
                        <label  class="app-input-top-label"> {{trans('planning-employment',209)}} </label>
                        <app-select v-model="cPlanData.id_employment_data_main" reduceValue :clearable="false"  :options="employments_list" :disable="cPlanData.department == null"  validatorName="Dienstverband" validatorRules="required"/>
    
                    </b-form-group>
                </template>
                <template v-slot:col-2>
                    <app-select v-model="cPlanData.contact_person" :label="trans('contactperson',169)" type="getContactPersons" :refreshIndex.sync="refresh_contacts" :args.sync="users_args" :disable="cPlanData.department == null" validatorName="Contactpersoon" validatorRules="required"/>
                </template>
            </app-row-left-label>

            <app-row-left-label :slots="[12,0]" :isMarginBetween="false" :customError="true">
                <template v-slot:col-1>
                    <label class="app-input-top-label">{{trans('planning-service-type',209)}}</label>
                    <b-row class="m-1">
                        <app-check-box id="ch_morning" class="custom-control-primary mr-4" v-model="cPlanData.morning" value="1" unchecked-value="0" :rightLabel="trans('day-part-morning',182)" size="lg" />
                        <app-check-box id="ch_day" class="custom-control-primary mr-4" v-model="cPlanData.day" value="1" unchecked-value="0" :rightLabel="trans('day-part-day',182)" size="lg"/>
                        <app-check-box id="ch_afternoon" class="custom-control-primary mr-4" v-model="cPlanData.afternoon" value="1" unchecked-value="0" :rightLabel="trans('day-part-afternoon',182)" size="lg"/>
                        <app-check-box id="ch_evening" class="custom-control-primary mr-4" v-model="cPlanData.evening" value="1" unchecked-value="0" :rightLabel="trans('day-part-evening',182)" size="lg" />
                        <app-check-box id="ch_night" class="custom-control-primary mr-4" v-model="cPlanData.night" value="1" unchecked-value="0" :rightLabel="trans('day-part-night',182)" size="lg"/>
                        <app-check-box id="ch_sleeping_service" class="custom-control-primary mr-4" v-model="cPlanData.sleeping_service" value="1" unchecked-value="0" :rightLabel="trans('sleeping-service',182)" size="lg"/>
                    </b-row>
                </template>

                <template #custom-error>
                    <small class="text-danger" v-if="cPlanData.split_invoice_error_message == 1">{{trans('field-required-error',180)}}</small>
                </template>
            </app-row-left-label>

            <b-row>
                <b-col>
                    <label  class="app-input-top-label"> {{trans('as-job-description',217)}} </label>
                    <app-memo v-model="cPlanData.work_description" :maxLength="500" :lettersCounter="true" :placeholder="trans('as-description-of-work',217)" :validatorName="trans('as-job-description',217)" validatorRules="required" />
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>
<script>

export default {
    components: {
    },

    props: ["planData"],
      
    computed: {
        cPlanData: {
            get() { return this.planData },
            set(value) { this.$emit('update:planData', value) }, 
        }
    },

    created(){
    },

    mounted(){
        
        if(this.planData.department != null){
            
            this.users_args.ids_department_data_main = this.planData.ids_department_data_main;
            this.refresh_contacts++;
        }
    },

    watch: {

        "planData.ids_department_data_main":{
            handler: function(val, oldVal) {
                
                if (this.planData.ids_department_data_main !== null && this.planData.ids_department_data_main.length > 0) {
                    
                    this.users_args.ids_department_data_main = this.planData.ids_department_data_main;
                    this.refresh_contacts++;

                }else{
                    this.cPlanData.contact_person.value = null;
                    this.cPlanData.contac_person.name = "";
                }
            } 
        },

         "planData.department": {
            handler: function(val) {
                 
                if (this.planData.department !== null && this.planData.ids_department_data_main.length > 0) {

                    this.users_args.ids_department_data_main = this.planData.ids_department_data_main;
                    this.refresh_contacts++;

                }else{
                    this.cPlanData.contact_person.value = null;
                    this.cPlanData.contac_person.name = "";
                }
            }         
        },

        "planData.morning": {
            handler: function(val) {
                if(this.planData.morning == 0 && this.planData.day == 0 && this.planData.evening == 0 && this.planData.night == 0){
                    this.cPlanData.split_invoice_error_message = 1;
                }else{
                    this.cPlanData.split_invoice_error_message = 0;
                }
            }
        },

        "planData.day": {
            handler: function(val) {
                if(this.planData.morning == 0 && this.planData.day == 0 && this.planData.evening == 0 && this.planData.night == 0){
                    this.cPlanData.split_invoice_error_message = 1;
                }else{
                    this.cPlanData.split_invoice_error_message = 0;
                }
            }
        },

        "planData.evening": {
            handler: function(val) {
                if(this.planData.morning == 0 && this.planData.day == 0 && this.planData.evening == 0 && this.planData.night == 0){
                    this.cPlanData.split_invoice_error_message = 1;
                }else{
                    this.cPlanData.split_invoice_error_message = 0;
                }
            }
        },

        "planData.night": {
            handler: function(val) {
                if(this.planData.morning == 0 && this.planData.day == 0 && this.planData.evening == 0 && this.planData.night == 0){
                    this.cPlanData.split_invoice_error_message = 1;
                }else{
                    this.cPlanData.split_invoice_error_message = 0;
                }
            }
        },
    },

    data() {
        return {
            users_args: {id_department_data_main: null},
            refresh_contacts: 0,
            employments_list: [
                { value: 2, name: this.trans('planning-temporary-worker',209) },
                { value: 3, name: this.trans('cp-zzp',190) },
                { value: 1, name: this.trans('planning-temporary-worker-and-self-employed',209) }
            ],
        };
    },
    
};
</script>

<style scoped>

</style>
