<template>
    <div>
        <b-row>
            <b-col lg="10" xl="8" offset-xl="2" offset-lg="1"> 
                <div class="mb-2">
                    <div class="mt-3 mb-1 d-flex align-items-center">
                        <router-link :to="{name: 'planning-offers'}">
                            <b-icon icon="arrow-left" class="mr-1"></b-icon>
                            {{trans('to-secondments',200)}}
                        </router-link>
                    </div>
                </div>
                <h1 class="mt-2 mb-4">{{trans('planning-create-secondment-order',209)}}</h1>

                <b-alert variant="success" :show="show_success_alert" @dismiss-count-down="countDownChangedSuccess" fade >
                        <div>
                            <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                            <span class="align-middle">{{trans('assignments-secondments-created-successfully',217)}}</span>
                        </div>        
                </b-alert>

                <ValidationObserver ref="form">
                    <b-row class="match-height mb-3">
                        <b-col md="6" class="pr-md-2 mb-sm-3 mb-md-0">
                            <b-card class="mb-3" >
                                <div class="app-card-profile-title mb-3">{{trans('planning-select-department',209)}}</div>
                                <offers-assignments-add-select-department-component :planData.sync="planData"/>

                                <b-alert variant="warning" show class="mt-3" v-if="planData.department != null && planData.department.id_cao_data_main == null">
                                    <b-row class="d-flex align-items-center">
                                        <b-col start cols="auto">
                                            <b-icon icon="file-earmark-plus-fill" font-scale="3"></b-icon>  
                                        </b-col>
                                        <b-col>
                                            <div class="font-weight-600 text-base">{{trans('planning-set-cao',209)}}</div>
                                            <div class="text-small">{{trans('planning-set-cao-at-departament-branch-organization',209)}}</div>
                                        </b-col>
                                        <b-col end cols="auto">
                                            <router-link :to="'/clients/department/' + planData.department.value">
                                                <app-button type="primary">
                                                    {{trans('planning-open-department-profile',210)}}
                                                </app-button>
                                            </router-link>                                          
                                        </b-col>
                                    </b-row>
                                </b-alert> 
                            </b-card>
                        </b-col>

                            <b-col md="6" class="pl-md-2">

                            <b-card class="mb-3" >
                                <div class="app-card-profile-title mb-3">{{trans('settings-functions',182)}}</div>
                                    <app-multi-select v-model="planData.ids_function_data_main" :label="trans('function',3)" :additionalLabel="trans('deviant-funtions',182)" labelPosition="top" :addButtonLabel="trans('planning-add-function',209)" type="getDepartmentsFunctions" validatorRules="required" validatorName="Functie" :validatorCustomMessage="{'required': trans('select-at-least-one-function',180)}" :args.sync="planData.department" :isAllOption="false" :refreshIndex.sync="planData.refreshFunctions" :key="refresh" :disabled="planData.department == null || planData.department.id_cao_data_main == null"/>
                            </b-card>

                            </b-col>
                    </b-row>


                    <offers-assignments-extra-data-component class="mb-3" :planData.sync="planData" v-if="planData.department != null"/>

                    <offers-assignments-plan-component class="mb-3" :planData.sync="planData" v-if="planData.department != null"/>

                    <offers-assignments-attachment-component class="mb-3" :planData.sync="planData" v-if="planData.department != null" />
                    
                </ValidationObserver>

                <div class="mt-4 mb-3" >
                    <app-button type="primary" :loading="loading" :disabled="loading_add_next" @click="add(true)">{{trans('as-create-assignment-close',218)}}</app-button>
                    <app-button type="primary" :loading="loading_add_next" :disabled="loading" @click="add(false)">{{trans('as-create-assignment-another-secondment',218)}}</app-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import OffersAssignmentsAddSelectDepartmentComponent from './Components/OffersAssignmentsAddSelectDepartmentComponent.vue';
import OffersAssignmentsExtraDataComponent from './Components/OffersAssignmentsExtraDataComponent.vue';
import OffersAssignmentsPlanComponent from './Components/OffersAssignmentsPlanComponent.vue';
import OffersAssignmentsAttachmentComponent from './Components/OffersAssignmentsAttachmentComponent.vue';

export default {
    components: {
        OffersAssignmentsAddSelectDepartmentComponent,
        OffersAssignmentsExtraDataComponent,
        OffersAssignmentsPlanComponent,
        OffersAssignmentsAttachmentComponent,
        ValidationObserver
    },


    data() {
        return {
            planData: {
                office: null,
                department: null,
                ids_department_data_main: [],
                function: null,
                client_location: null,

                contact_person: null,
                id_employment_data_main: null,
                morning: 0,
                day: 0,
                afternoon: 0,
                evening: 0,
                night: 0, 
                sleeping_service: 0,
                work_description: "",

                prefered_start_date:null,
                last_start_date:null,
                end_date:null,
                min_per_week:null,
                max_per_week:null,

                additional_functions:{
                    items:{
                        list:[],
                        total: 0,
                        refresh: 0,
                    },
                    page:1,
                },  

                document: [],

                refreshFunctions:0,
                users_args: {
                        id_location_data_main: null, 
                        id_office_data_location:null
                    },
                split_invoice_error_message: 0,
                ids_function_data_main: []
            },

            show_success_alert: false,
            success_dismiss_count_down: 0,
            dismiss_secs: 3,
            loading: false,
            loading_add_next: false,
            functions_additional: [],  
            id: -1,
            refresh: 0,

        };
    },

    watch:{
        "planData.ids_function_data_main": {
            handler: function(newVal, oldVal) {
                    if(this.id != this.planData.ids_function_data_main[0]){
                        this.addAdditionals();
                        this.id = this.planData.ids_function_data_main[0];
                    }
            },
            deep: true,
        },

        "planData.department": {
            handler: function(newVal, oldVal) {
                    this.planData.ids_function_data_main = [];
                    this.functions_additional = [];
                    if(this.planData.department != null && this.planData.department.id_cao_data_main != null){
                        this.refresh++;
                    }
            },
            deep: true,
        },
        "planData.office":{
            handler: function(newVal, oldVal) {
                    if(oldVal != null && (newVal.value != oldVal.value)){
                        this.planData.ids_function_data_main = [];
                        this.functions_additional = [];
                    }
                    
            },
            deep: true,
        },

        "planData.client_location":{
            handler: function(newVal, oldVal) {
                    
                    if( newVal == null || (oldVal != null && (newVal.value != oldVal.value))){
                        this.planData.ids_function_data_main = [];
                        this.functions_additional = [];
                    }
                    
            },
            deep: true,
        }
    },

    methods: {

        countDownChangedSuccess(success_dismiss_count_down){
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        add(closeWizard) {

            if(this.planData.morning == 0 && this.planData.day == 0 && this.planData.evening == 0 && this.planData.night == 0 && this.planData.afternoon == 0 && this.planData.sleeping_service == 0){
                this.planData.split_invoice_error_message = 1;
            }else{
                this.planData.split_invoice_error_message = 0;
            }

            this.$refs.form.validate().then((success) => {
                if (success && this.planData.department != null && this.planData.split_invoice_error_message == 0) {

                    if(closeWizard) {
                        this.loading = true;
                    }else{
                        this.loading_add_next = true;
                    }

                    axios
                        .post("planning/assignments/add", {
                            id_office_data_location: this.planData.office.value, 
                            id_department_data_main: this.planData.department.value,
                            ids_department_data_main: this.planData.ids_department_data_main, 
                            ids_function_data_main: this.planData.ids_function_data_main, 
                            id_cao_data_main: this.planData.department.id_cao_data_main, 
                            id_client_data_main: this.planData.client_location.id_client_data_main,
                            id_location_data_main: this.planData.client_location.value, 
                            id_employment_data_main: this.id_employment_data_main,
                            id_contact_data_main: this.planData.contact_person.value,
                            morning: this.planData.morning,
                            day: this.planData.day,
                            afternoon: this.planData.afternoon,
                            evening: this.planData.evening,
                            night: this.planData.night,
                            sleeping_service: this.planData.sleeping_service,
                            work_changes: JSON.stringify(this.planData.work_change),
                            work_description: this.planData.work_description,
                            prefered_start_date: this.planData.prefered_start_date, 
                            last_start_date: this.planData.last_start_date, 
                            end_date: this.planData.end_date,
                            hour_min_per_week: this.planData.min_per_week, 
                            hour_max_per_week: this.planData.max_per_week, 
                            documents: JSON.stringify(this.planData.document),
                        })
                        .then((res) => {

                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false,
                            this.loading_add_next = false

                            if(closeWizard) {
                                this.$router.push({ name: "planning-offers", params: { } });
                            }else{
                                this.show_success_alert = this.dismiss_secs;
                                document.getElementById('app').scrollIntoView({
                                                                            behavior: "smooth",
                                                                            block: "start",
                                                                        });
                            }

                        });

                }else{

                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.form.refs)
                            .map(([key, value]) => ({
                                key,
                                value,
                            }))
                            .filter((error) => {
                                if (!error || !error.value || !error.value.failedRules) return false;
                                return Object.keys(error.value.failedRules).length > 0;
                            });
                        if (errors && errors.length > 0) {
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                    }, 100);

                }

            }); 
        },

        addAdditionals(){
            if(this.planData.department != null){
                var additional_functions_array = JSON.parse(this.planData.department.additional_functions)
                this.functions_additional = additional_functions_array;

                var functions = [];

                if(this.planData.ids_function_data_main[0] != null){
                    this.planData.ids_function_data_main = [this.planData.ids_function_data_main[0]]
                }
                if(this.functions_additional == null){
                    this.functions_additional = [];
                    return
                }

                for(var i=0; i<this.functions_additional.length; i++){
                    if(this.planData.ids_function_data_main.includes(this.functions_additional[i].id_function_data_main)){
                        functions = this.planData.ids_function_data_main.concat(this.functions_additional[i].ids_function_data_additional);
                        this.planData.ids_function_data_main = functions;
                        functions = [];
                        break;
                    }else if(this.planData.ids_function_data_main[0] != null){
                        this.planData.ids_function_data_main = [this.planData.ids_function_data_main[0]]
                    }
                }
                this.refresh++;
            }
        }
    },
 
};
</script>