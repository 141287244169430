<template>
    <div>
        <b-card>
            <div class="app-card-profile-title mb-3">{{trans('as-attachments',212)}}</div>

            <b-form @submit.prevent>
                <b-row class="p-3" v-if="planData.document.length > 0">
                    <b-col>
                        <b-row v-for="(item_file, index) in planData.document" :key="item_file.id">
                            <b-col md="8" xl="8">
                                <span>{{ item_file.file_name }}</span>
                            </b-col>
                            <b-col md="2" xl="2">
                                <span class="text-right font-weight-bolder"> {{ item_file.file_size_mb }} MB </span>
                            </b-col>
                            <b-col md="2" xl="2">
                                <b-link href="#" @click="deleteFile(index)">
                                    <span class="text-right" v-if="fCancel == 0 || (fCancel != 0 && fCancel !== index+1)">{{trans('remove',175)}}</span>
                                    <b-spinner class="ml-4" small v-if="fCancel === index+1" />
                                </b-link>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col v-if="(planData.document == null || !(planData.document.length === this.maxFilesNumber))">  
                        <div class="image-upload-wrap">
                            <input class="file-upload-input" type="file" v-on:change="addFile" :accept="accept_files" ref="imageUploader"/>
                            <div class="drag-text">
                                <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!fUploading" />
                                <b-spinner v-if="fUploading" class="my-1" />
                                <div>
                                    <small class="text-muted">{{trans('drag-and-drop-files-jpg-png-pdf-doc-xls',180)}}</small>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </div>
</template>
<script>
import axios from "@axios";

export default {

    
    props: {
            planData: Object, 
            maxFilesNumber: Number,
            accept_files: {
                default: "image/png, image/jpeg, image/jpg, application/pdf, application/msword, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                type: String
            },
    },

    computed: {
        cPlanData:{
            get() { return this.planData },
            set() { this.$emit('update:planData', false) }
        }
    },

    data() {
        return {
            formData: new FormData(),
            fUploading: false,
            fCancel:0,
        };
    },

    methods: {
       addFile(e) {

            var file = e.target.files[0];
            if(!file.type.match('image/jp.*') && !file.type.match('image/png.*') && !file.type.match('application/pdf.*') && !file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document') && !file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.*') && !file.type.match('application/msword.*')) {
                alert(this.trans('only-doc-pdf-xls-jpg-png',216));
                file = "";
                return;
            }

            this.formData.append("file", file);
            this.formData.append("file_name", file.name);
            this.formData.append("file_size", file.size);
            this.formData.append("file_size_mb", (file.size / 1024 / 1024).toFixed(2));

            this.sendFile();
        },

         sendFile() {
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
     
            this.fUploading = true;

            axios
                .post("core/addTempFile", this.formData, config)                
                .then((response) => {
                    this.formData.append("temp_path", response.data);                  
                
                    var object = {};
                    this.formData.forEach((value, key) => object[key] = value);                    
                    this.cPlanData.document.push(object);

                    this.formData = new FormData();
                    this.fUploading = false;
                    this.$refs.imageUploader.value = '';
                })
                .catch(function(error) {
                    //this.fUploading = false;

                    console.log(error);
                });
        },

        deleteFile: function(index) {
            var doc = [];
            doc.push(this.planData.document[index])
            if(doc.length > 0) {
                this.fCancel = index+1;
                axios
                .post("core/removeTempFile",  {
                            documents: JSON.stringify(doc),
                        })
                .then((response) => {
                    this.fCancel = 0;
                    this.cPlanData.document.splice(index, 1);
                })
                .catch(function(error) {
                    this.fCancel = 0;
                    console.log(error);
                });
            }
        },
    },
    
};
</script>

<style scoped>
.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}
.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.fileContainer {
    font-size: 12px;
    border: none;
    padding: 0 0 10px 0;
}
.fileContainer:hover {
    background-color: transparent;
}
.deleteButton {
    color: #71e6ee;
    cursor: pointer;
    float: left;
}
.files {
    width: 43%;
}
.loading {
    margin-left: 40%;
}
.uploadIcon {
    width: 13%;
}
</style>

