<template>
    <div>
        <b-card>               
            <div class="app-card-profile-title mb-3">{{trans('planning-plan-data',173)}}</div>

            <app-row-left-label :slots="[4, 4, 4]" :isMarginBetween="false">
                <template v-slot:col-1>
                    <app-date v-model="cPlanData.prefered_start_date" :label="trans('as-desired-start-date',217)" validatorName="Gewenste startdatum" validatorRules="required|date-check|date-more-check" :validatorCustomMessage="{ 'date-more-check': trans('planning-start-date-in-past-error',211) }" :disabledDatesPass="1"/>
                </template>
                <template v-slot:col-2>
                    <app-date v-model="cPlanData.last_start_date" :key="refresh_date" :label="trans('planning-last-start-date',209)" validatorName="Laatste startdatum" :validatorRules="'required|date-check|date-more-check|date-more-than:'+cPlanData.prefered_start_date" :validatorCustomMessage="{ 'date-more-check': trans('planning-last-start-date-in-past-error',211) }" :disabledDatesPass="cDisabledDatesLast"/>
                </template>
                <template v-slot:col-3>
                    <app-date v-model="cPlanData.end_date" :key="refresh_date" :label="trans('date-to',182)" validatorName="Einddatum" :validatorRules="'required|date-check|date-more-check|date-more-than:'+cPlanData.last_start_date" :validatorCustomMessage="{ 'date-more-check': trans('end-date-past-error',199) }" :disabledDatesPass="cDisabledDatesEnd"/>
                </template>
            </app-row-left-label>

            <label class="app-input-top-label mt-2"> {{trans('planning-number-of-hours-per-week',209)}}</label>
            <app-row-left-label :slots="[4, 4, 4]" :isMarginBetween="false">
                <template v-slot:col-1>
                    <app-input v-model="cPlanData.min_per_week" :label="trans('planning-min',209)" validatorName="Min" validatorRules="required|weekcheck" type="integer_02" />
                </template>
                <template v-slot:col-2>
                    <app-input v-model="cPlanData.max_per_week" :key="refresh_min_value" :label="trans('planning-max',209)" validatorName="Max"  :validatorRules="'required|weekcheck|number-more-than:'+cPlanData.min_per_week" type="integer_02" :validatorCustomMessage="{ 'number-more-than': trans('invalid-hour',199) }"/>
                </template>
            </app-row-left-label>

        </b-card>
    </div>
</template>
<script>

import { datecheck } from "@validations";
import moment from "moment";
export default {
  components: { },

    props: ["planData"],

    watch: {
         "planData.prefered_start_date": {
            handler: function(val) {
                this.cPlanData.last_start_date = this.planData.prefered_start_date;
                this.refresh_date++;
            },
        },
        
        "planData.min_per_week": {
            handler: function(val) {
                this.cPlanData.max_per_week = this.planData.min_per_week;
                this.refresh_min_value++;
            },
        }
    },

    computed: {
        cPlanData:{
            get() { return this.planData },
            set() { this.$emit('update:planData', false) }
        },
        cDisabledDatesLast: {
            get() {
                var today = moment(moment.now());
                var d = today.diff(moment(this.cPlanData.prefered_start_date, 'DD-MM-YYYY'), 'days');
                return today.format('DD-MM-YYYY') == this.cPlanData.prefered_start_date ? 1 : d;
            }
        },

        cDisabledDatesEnd: {
            get() {
                var today = moment(moment.now());
                var d = today.diff(moment(this.cPlanData.last_start_date, 'DD-MM-YYYY'), 'days');
                return today.format('DD-MM-YYYY') == this.cPlanData.prefered_start_date ? 1 : d;
            }
        },
    },

    data() {
        return {
            refresh_date:0,
            refresh_min_value: 0
        };
    },
    

};
</script>

<style scoped>
.app-local-week-width{
    width:100px;
}
</style>
