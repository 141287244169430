<template>
    <div>
        <b-row>
            <b-col cols="12">
                <app-select v-model="cPlanData.office" :label="trans('planning-hn-branch',209)" type="getUserLocations" :disable="false" validatorName="HN Vestiging" validatorRules="required" :refreshIndex.sync="refreshUserLocations" @loaded="setOffice"/>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <app-select v-model="cPlanData.client_location" :label="trans('location',3)" type="getLocationsOffers" :refreshIndex.sync="refreshClientLocations" :args.sync="cPlanData.users_args" :disable="cPlanData.office == null" validatorName="Locatie" validatorRules="required"/>
            </b-col>
            <b-col cols="12"  class="d-flex w-100">
                <app-multi-select ref="department" class="w-100" v-model="cPlanData.ids_department_data_main" labelPosition="top" :isAllOption="false" :label="trans('department',182)" type="getDepartments" :refreshIndex.sync="refreshDepartments" :args.sync="cPlanData.users_args" :disabled="cPlanData.client_location == null" :isSelectableInFirst="false" :selectable="(cPlanData.department != null && cPlanData.ids_department_data_main.length >= 1) ? ((option) => option.id_cao_data_main == cPlanData.department.id_cao_data_main) : () => true" :firstItem.sync="cPlanData.department" validatorName="Afdeling" validatorRules="required"/>
            </b-col> 
        </b-row>  
    </div>
</template>
<script>

export default {

    props: ["planData"],

    mounted() {
        this.refreshUserLocations++;
    },

    watch: {
        
        "planData.office": {
            handler: function(val) {
              if (this.planData.office !== null) {
                    this.cPlanData.users_args.id_office_data_location = this.planData.office.value;
                   
                    if (this.cPlanData.client_location  != null){
                        this.cPlanData.client_location.name = "";
                        this.cPlanData.client_location.value = null;
                        this.cPlanData.client_location = null;
                    }
                    
                    if (this.cPlanData.department  != null){
                        this.cPlanData.department.name = "";
                        this.cPlanData.department.value = null;
                        this.cPlanData.department.id_cao_data_main = null;
                        this.cPlanData.department = null;
                    }
                    this.cPlanData.ids_department_data_main.splice(0, this.cPlanData.ids_department_data_main.length);
                    this.$refs.department.setData();

                    if (this.cPlanData.function  != null){
                        this.cPlanData.function.name = "";
                        this.cPlanData.function.value = null;
                        this.cPlanData.function = null;
                    }

                    this.refreshClientLocations++;
                
                }
            }
        },

        "planData.client_location": {
            handler: function(val) {
               
                if (this.planData.client_location !== null) {
                    this.cPlanData.users_args.id_location_data_main = this.planData.client_location.value;

                    if (this.cPlanData.department  != null){
                        this.cPlanData.department.name = "";
                        this.cPlanData.department.value = null;
                        this.cPlanData.department.id_cao_data_main = null;
                        this.cPlanData.department = null;
                        
                    }

                    
                    this.cPlanData.ids_department_data_main.splice(0, this.cPlanData.ids_department_data_main.length);
                    this.$refs.department.setData();    

                    if (this.cPlanData.function  != null){
                        this.cPlanData.function.name = "";
                        this.cPlanData.function.value = null;
                        this.cPlanData.function = null;
                    }
                    
                    this.refreshDepartments++;
                }
            }         
        },

        "planData.department": {
            handler: function(val, oldVal) {
                
                if (this.planData.department !== null) {

                    if (this.cPlanData.function  != null){
                        this.cPlanData.function.name = "";
                        this.cPlanData.function.value = null;
                        this.cPlanData.function = null;
                    }
                    
                    if(this.cPlanData.department.id_cao_data_main != null){
                        this.cPlanData.refreshFunctions++;
                    }
                    
                    if(this.cPlanData.ids_department_data_main.length == 1){
                        this.cPlanData.id_employment_data_main = this.cPlanData.department.zzp_preferences;
                    }else{
                        this.cPlanData.id_employment_data_main = null;
                    }
                    
                }

                if(this.cPlanData.ids_department_data_main.length > 1 && val.id_cao_data_main != oldVal.id_cao_data_main){
                    this.cPlanData.ids_department_data_main.splice(1, this.cPlanData.ids_department_data_main.length);
                    this.$refs.department.setData();
                }
            }         
        },

        "planData.ids_department_data_main": {
            handler: function(val, oldVal) {
                if(val.length > 1 || val.length == 0){
                    this.cPlanData.id_employment_data_main = null;
                }else if(val.length == 1){
                    this.cPlanData.id_employment_data_main = this.cPlanData.department.zzp_preferences;
                }
            } 
        }
    },

    computed: {
        cPlanData: {
            get() { return this.planData },
            set() { this.$emit('update:planData', false) }
        }
    },

    data() {
        return {           
            refreshDepartments: 1,
            refreshClientLocations: 1,
            refreshUserLocations: 1,
        };
    },

    methods: {
        setOffice(event){
            var user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));
            
            if(user_locations.length == 1){
                if( event.filter(i => i.value == user_locations[0].value).length == 1 ){

                    this.cPlanData.office = user_locations[0];
                }
            }
        }
    }
   
};
</script>

<style></style>
